import CombinedService from '../../services/combined.service'

export const state = {
    //selectedCartProduct: {},
    //orders: [],
    //posts: []
};

export const mutations = {
    FIND_COMBO(state, value) {
        const  { userObj, business, orders, selectedPortfolio, portfolios, posts } = value;
        state.selectedBusiness = business
        state.orders = orders
        state.posts = posts
    },
};

export const actions = {
    
    async findCombo({commit}, payload) {
        try {
            const responses = await CombinedService.combo(payload)
            
            commit('FIND_COMBO', responses)
            return responses
        }
        catch(error) {
            console.log('find combo action', error)
        }
    },
};

export const getters = {
    
};
