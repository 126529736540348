import http from "../common/http";

class OrderService {

  createOrder(data) {
    return http.request('post', '/order/create', data);
  }
  
  updateOrder(data) {
    return http.request('put', '/order/update', data);
  }

  updateOrderStatus(data) {
    return http.request('post', '/order/status/update', data);
  }

  findAllOrders(userId, page, limit, search) {
    return http.request('get', `/order/find-all?userId=${userId}&page=${page}&limit=${limit}&search=${search}`);
  }

  findOneOrder(userId, orderNumber) {
    return http.request('get', `/order/find-one?userId=${userId}&orderNumber=${orderNumber}`);
  }

  deleteOrder(userId) {
    return http.request('delete', `/order/delete?userId=${userId}`);
  }

  findOrderBySessionToken(sessionToken) {
    return http.request('get', `/order/find-one/session?sessionToken=${sessionToken}`);
  }

  findOrdersByBusinessId(businessId) {
    return http.request('get', `/order/find-all/business-id?businessId=${businessId}`);
  }
}

export default new OrderService();
