import http from "../common/http";

class PlatformService {
  create(data) {
    return http.request('post', '/platform/create', data);
  }
  
  update(data) {
    return http.request('put', '/platform/update', data);
  }

  findAll(streamId) {
    return http.request('get', `/platform/find-all?streamId=${streamId}`);
  }

  findOne(id) {
    return http.request('get', `/platform/find-one?id=${id}`);
  }

  delete(id) {
    return http.request('delete', `/platform/delete?id=${id}`);
  }
}

export default new PlatformService();
