import PortfolioService from '../../services/portfolio.service'

export const state = {
    portfolioStreams: [],
    selectedPortfolioStream: {},
    portfolioProducts: [],
    businessProducts: [],
    businessReviews: [],
};

export const mutations = {
    ADD_TO_PORTFOLIO(state, value) {
        state.portfolioStreams.push(value)
    },

    // UPDATE_PLATFORM(state, value) {
    //     let index = state.platforms.findIndex(platform => platform.id == value.id)
    //     state.platforms[index] = value
    // },

    SET_PORTFOLIOS(state, value) {
        state.portfolios = value
    },

    SET_PORTFOLIO(state, value) {
        state.selectedPortfolio = value
    },

    DELETE_PORTFOLIO(state, value) {
        let index = state.portfolios.findIndex((res) => res.id == value)
        state.portfolios.splice(index, 1)
    },

    ADD_PRODUCT_TO_PORTFOLIO(state, value) {
        state.portfolioProducts.push(value)
    },

    DELETE_PRODUCT_FROM_PORTFOLIO(state, value) {
        let index = state.portfolioProducts.findIndex((res) => res.businessProductId == value)
        state.portfolioProducts.splice(index, 1)
    },
 
    SET_PORTFOLIO_PRODUCTS(state, value) {
        state.portfolioProducts = value
    },

    SET_INCOME_STREAM_PRODUCTS(state, value) {
        state.businessProducts = value
    },

    ACTIVATE_PRODUCT(state, value) {
        console.log('ACTIVATE_PRODUCT payload', value)
        if(value.nodeType == 'Business') {
            let index = state.businessProducts.findIndex((res) => res.id == value.id)
            state.businessProducts[index].active = !value.active
        }
        else {
            let index = state.portfolioProducts.findIndex((res) => res.id == value.id)
            state.portfolioProducts[index].active = !value.active
        }
        
    },

    ADD_BUSINESS_REVIEW(state, value) {
        state.businessReviews.push(value)
    },

    SET_BUSINESS_REVIEWS(state, value) {
        state.businessReviews = value
    },
};

export const actions = {
    
    async addToPortfolio({commit}, payload) {
        try {
            let response = await PortfolioService.addToPortfolio(payload)
            console.log('respnse for add to port', response)
            //commit('CREATE_PORTFOLIO', response[0])
            return response
        }
        catch(error) {
            console.log('add to portfolio action', error)
        }
    },

    async findOnePortfolio({ commit }, payload) {
        try {
            const { userId, businessId } = payload
            let response = await PortfolioService.findOnePortfolio(userId, businessId)
            return response
        }
        catch(error) {
            console.log('find portfolio action', error)
            throw error
        }
    },

    async findAllPortfolios({ commit }, payload) {
        try {
            const { userId, businessId } = payload
            let response = await PortfolioService.findAllPortfolios(userId)
            return response
        }
        catch(error) {
            console.log('find portfolio action', error)
            throw error
        }
    },

    async findPortfolioStreams({ commit }, userId) {
        try {
            let response = await PortfolioService.findAllPortfolioStreams(userId)
            //commit('SET_PORTFOLIOS', response)
            return response
        }
        catch(error) {
            console.log('find all portfolio streams action', error)
            throw error
        }
    },

    async findPortfolioStream({ commit }, payload) {
        try {
            const { businessId, userId } = payload;
            let response = await PortfolioService.findOnePortfolioStream(businessId, userId)
            console.log('payload', payload)
            console.log('respsonsee', response)
            // if(response.length > 0) {
            //   commit('SET_PORTFOLIO', response[0])
            //     commit('SET_PORTFOLIO_PRODUCTS', response[0].products)  
            // }
            return response
        }
        catch(error) {
            console.log('find one portfolio action', error)
        }
    },

    async deleteFromPortfolio({commit}, payload) {
        const { businessId, userId } = payload;
        try {
            console.log('delete payload', payload)
            let response = await PortfolioService.deleteFromPortfolio(businessId, userId)
            //commit('DELETE_PORTFOLIO', businessId)
            return response
        }
        catch(error) {
            console.log('delete portfolio stream action', error)
        }
    },

    async addProductToPortfolio({commit}, payload) {
        try {
            let response = await PortfolioService.addProductToPortfolio(payload)
            console.log('response for add product to portfolio', response)
            commit('ADD_PRODUCT_TO_PORTFOLIO', response[0]);
            return response
        }
        catch(error) {
            console.log('add product to portfolio action', error)
        }
    },

    async deleteProductFromPortfolio({commit}, payload) {
        const { portfolioId, productId } = payload;
        try {
            console.log('delete payload', payload)
            let response = await PortfolioService.deleteProductFromPortfolio(portfolioId, productId)
            commit('DELETE_PRODUCT_FROM_PORTFOLIO', productId)
            return response
        }
        catch(error) {
            console.log('delete portfolio product action', error)
        }
    },

    setbusinessProducts({commit}, payload) {
        try {
            commit('SET_INCOME_STREAM_PRODUCTS', payload);
            return payload
        }
        catch(error) {
            console.log('setbusinessProducts action', error)
        }
    },

    async activateProduct({commit}, payload) {
        try {
            //let response = await PortfolioService.activateProduct(payload)
            //console.log('response for activate product', response)
            commit('ACTIVATE_PRODUCT', payload);
            return payload
        }
        catch(error) {
            console.log('activate product action', error)
        }
    },

    async addBusinessReview({commit}, payload) {
        try {
            let response = await PortfolioService.addReview(payload)
            commit('ADD_BUSINESS_REVIEW', response[0])
            return response
        }
        catch(error) {
            console.log('add review action', error)
        }
    },

    async findAllbusinessReviews({ commit }, businessId) {
        try {
            let response = await PortfolioService.findAllReviews(businessId)
            commit('SET_BUSINESS_REVIEWS', response)
            return response
        }
        catch(error) {
            console.log('find all portfolio reviews action', error)
            throw error
        }
    },
};

export const getters = {
    
};
