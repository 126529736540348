import OrderService from '../../services/order.service'

export const state = {
    cartProducts: [],
    selectedCartProduct: {},
    orders: [],
};

export const mutations = {

    CREATE_ORDER(state, value) {
        state.orders.push(value)
    },

    UPDATE_ORDER(state, value) {
        if(value) {
            state.orders = []
            state.cartProducts = []
            state.selectedCartProduct = {} 
        }
    },

    FIND_ALL_ORDERS(state, value) {
        state.orders = value
    },

    DELETE_ORDER(state, value) {
        let index = state.orders.findIndex((res) => res.id == value)
        state.orders.splice(index, 1)
    },  
};

export const actions = {

    async createOrder({commit}, payload) {
        try {
            let response = await OrderService.createOrder(payload)
            commit('CREATE_ORDER', response[0])
            return response
        }
        catch(error) {
            console.log('create order action', error)
        }
    },
    
    async updateOrder({commit}, payload) {
        try {
            let success = payload.checkout
            let response = await OrderService.updateOrder(payload)
            commit('UPDATE_ORDER', success)
            return response
        }
        catch(error) {
            console.log('update cart action', error)
        }
    },

    async findAllOrders({ commit }, userId) {
        try {
            let response = await OrderService.findAllOrders(userId)
            commit('FIND_ALL_ORDERS', response)
            return response
        }
        catch(error) {
            console.log('find all orders action', error)
            throw error
        }
    },

    async deleteOrder({commit}, productId) {
        try {
            let response = await OrderService.deleteOrder(productId)
            commit('DELETE_ORDER', productId)
            return response
        }
        catch(error) {
            console.log('delete order action', error)
        }
    },
};

export const getters = {
    
};
