import PlatformService from '../../services/platform.service'

export const state = {
    platforms: [],
    selectedPlatform: {}
};

export const mutations = {
    ADD_PLATFORM(state, value) {
        state.platforms.push(value)
    },

    UPDATE_PLATFORM(state, value) {
        let index = state.platforms.findIndex(platform => platform.id == value.id)
        state.platforms[index] = value
    },

    SET_PLATFORMS(state, value) {
        state.platforms = value
    },

    SET_ONE_PLATFORM(state, value) {
        state.selectedPlatform = value
    },

    DELETE_PLATFORM(state, value) {
        let index = state.platforms.findIndex((res) => res.id == value)
        state.platforms.splice(index, 1)
    },
};

export const actions = {
    
    async createPlatform({commit}, payload) {
        try {
            let response = await PlatformService.create(payload)
            commit('ADD_PLATFORM', response[0])
            return response
        }
        catch(error) {
            console.log('create platform action', error)
        }
    },
    
    async updatePlatform({commit}, payload) {
        try {
            let response = await PlatformService.update(payload)
            commit('UPDATE_PLATFORM', response[0])
            return response
        }
        catch(error) {
            console.log('update platform action', error)
        }
    },

    async findAllPlatforms({ commit }, streamId) {
        try {
            let response = await PlatformService.findAll(streamId)
            commit('SET_PLATFORMS', response)
            return response
        }
        catch(error) {
            console.log('find all platforms action', error)
            throw error
        }
    },

    async findOnePlatform({ commit }, id) {
        try {
            let response = await PlatformService.findOne(id)
            commit('SET_ONE_PLATFORM', response)
            return response
        }
        catch(error) {
            console.log('find one platform action', error)
        }
    },

    async deletePlatform({commit}, id) {
        try {
            let response = await PlatformService.delete(id)
            commit('DELETE_PLATFORM', id)
            return response
        }
        catch(error) {
            console.log('delete platform action', error)
        }
    },
};

export const getters = {
    
};
