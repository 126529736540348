import http from "../common/http-video";

class ProductVideoService {
  createProductVideo(data) {
    return http.request('post', '/product-video/create', data);
  }
  
  updateProductVideo(data) {
    return http.request('put', '/product-video/update', data);
  }

  findAllProductVideos(productId) {
    console.log('findAllProductVideos', productId)
    return http.request('get', `/product-video/find-all?productId=${productId}`);
  }

  findOneProductVideo(productId, productVideoId) {
    return http.request('get', `/product-video/find-one?productId=${productId}&productVideoId=${productVideoId}`);
  }

  deleteProductVideo(productId, productVideoId) {
    return http.request('delete', `/product-video/delete?productId=${productId}&productVideoId=${productVideoId}`);
  }

  generateQRCodeImage(data) {
    return http.request('post', '/product-video/qrcode/generate', data);
  }

  downloadProductVideo(data) {
    return http.request('post', '/product-video/download', data);
  }

  deleteVideoFile(data) {
    return http.request('post', '/product-video/local/delete', data);
  }
}

export default new ProductVideoService();
