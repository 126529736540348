import ProductService from '../../services/product.service'

export const state = {
    products: [],
    selectedProduct: {},
};

export const mutations = {
    ADD_PRODUCT(state, value) {
        state.products.unshift(value)
    },

    ADD_PRODUCTS(state, value) {
        state.products.unshift(...value)
    },

    UPDATE_PRODUCT(state, value) {
        let index = state.products.findIndex(product => product.id == value.id)
        state.products[index] = value
    },

    SET_PRODUCTS(state, value) {
        state.products = value
    },

    SET_ONE_PRODUCT(state, value) {
        let index = state.products.findIndex(product => product.id == value.id)
        state.products[index] = value
    },

    DELETE_PRODUCT(state, value) {
        let index = state.products.findIndex((res) => res.id == value)
        state.products.splice(index, 1)
    },
};

export const actions = {
    
    async createProduct({commit}, payload) {
        try {
            let response = await ProductService.create(payload)
            commit('ADD_PRODUCT', response)
            return response
        }
        catch(error) {
            console.log('create product action', error)
        }
    },
    
    async updateProduct({commit}, payload) {
        try {
            let response = await ProductService.update(payload)
            commit('UPDATE_PRODUCT', response[0])
            return response
        }
        catch(error) {
            console.log('update product action', error)
        }
    },

    async findAllProducts({ commit }) {
        try {
            let response = await ProductService.findAll()
            commit('SET_PRODUCTS', response.products)
            return response.products
        }
        catch(error) {
            console.log('find all products action', error)
            throw error
        }
    },

    async findOneProduct({ commit }, id) {
        try {
            let response = await ProductService.findOne(id)
            commit('SET_ONE_PRODUCT', response)
            return response
        }
        catch(error) {
            console.log('find one product action', error)
        }
    },

    async deleteProduct({commit}, id) {
        try {
            let response = await ProductService.delete(id)
            commit('DELETE_PRODUCT', id)
            return response
        }
        catch(error) {
            console.log('delete product action', error)
        }
    },

    async addToCart({commit}, payload) {
        try {
            let response = await ProductService.addToCart(payload)
            commit('ADD_TO_CART', response[0])
            return response
        }
        catch(error) {
            console.log('add to cart action', error)
        }
    },

};

export const getters = {
    
};
