import SalesService from '../../services/sales.service'

export const state = {
    sales: [],
};

export const mutations = {

    FIND_ALL_SALES(state, value) {
        state.sales = value
    },
};

export const actions = {

    async findAllOrders({ commit }, userId) {
        try {
            let response = await SalesService.findAllSales(userId)
            commit('FIND_ALL_SALES', response)
            return response
        }
        catch(error) {
            console.log('find all sales action', error)
            throw error
        }
    },
};

export const getters = {
    
};
