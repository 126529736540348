import http from "../common/http";

class PostService {

  createPost(data) {
    return http.request('post', '/post/create', data);
  }
  
  updatePost(data) {
    return http.request('put', '/post/update', data);
  }

  findAllPosts(userId, businessId) {
    return http.request('get', `/post/find-all?userId=${userId}&businessId=${businessId}`);
  }

  deletePost(creatorId, postId) {
    return http.request('delete', `/post/delete?creatorId=${creatorId}&postId=${postId}`);
  }

  likePost(data) {
    return http.request('post', '/post/like', data);
  }

  createPostComment(data) {
    return http.request('post', '/post/comment/create', data);
  }

  updatePostComment(data) {
    return http.request('put', `/post/comment/update`, data);
  }

  findAllPostComments(postId) {
    return http.request('get', `/post/comment/find-all?postId=${postId}`);
  }


  deletePostComment(commentId, isReply) {
    return http.request('delete', `/post/comment/delete?commentId=${commentId}&isReply=${isReply}`);
  }
}

export default new PostService();
