import ProductVideoService from '../../services/product-video.service'

export const state = {
    productVideos: [],
    selectedProductVideo: {}
};

export const mutations = {
    ADD_PRODUCT_VIDEO(state, value) {
        state.productVideos.push(value)
    },

    EDIT_PRODUCT_VIDEO(state, value) {
        let index = state.productVideos.findIndex(video => video.id == video.id)
        state.productVideos[index] = value
    },

    SET_PRODUCT_VIDEOS(state, value) {
        state.productVideos = value
    },

    SET_PRODUCT_VIDEO(state, value) {
        state.selectedProductVideo = value
    },

    DELETE_PRODUCT_VIDEO(state, value) {
        let index = state.productVideos.findIndex((res) => res.id == value)
        state.productVideos.splice(index, 1)
    },
};

export const actions = {
    
    async addProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.createProductVideo(payload)
            commit('ADD_PRODUCT_VIDEO', response[0])
            return response
        }
        catch(error) {
            console.log('create product video action', error)
        }
    },
    
    async editProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.updateProductVideo(payload)
            commit('EDIT_PRODUCT_VIDEO', response[0])
            return response
        }
        catch(error) {
            console.log('update product video action', error)
        }
    },

    async getAllProductVideos({ commit }, productId) {
        try {
            let response = await ProductVideoService.findAllProductVideos(productId)
            commit('SET_PRODUCT_VIDEOS', response)
            return response
        }
        catch(error) {
            console.log('find all product videos action', error)
            throw error
        }
    },

    async getOneProductVideo({ commit }, payload) {
        try {
            let response = await ProductVideoService.findOneProductVideo(payload.productId, payload.ProductVideoId)
            commit('SET_PRODUCT_VIDEO', response)
            return response
        }
        catch(error) {
            console.log('find one product video action', error)
        }
    },

    async removeProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.deleteProductVideo(payload.productId, payload.ProductVideoId)
            commit('DELETE_PRODUCT_VIDEO', id)
            return response
        }
        catch(error) {
            console.log('delete product video action', error)
        }
    },

    async generateQRCodeImage({commit}, payload) {
        try {
            let response = await ProductVideoService.generateQRCodeImage(payload)
            return response
        }
        catch(error) {
            console.log('error generating qr code action', error)
        }
    },

    async downloadProductVideo({commit}, payload) {
        try {
            let response = await ProductVideoService.downloadProductVideo(payload)
            return response
        }
        catch(error) {
            console.log('error downloading product video action', error)
        }
    },

    async deleteVideoFile({commit}, payload) {
        try {
            let response = await ProductVideoService.deleteVideoFile(payload)
            return response
        }
        catch(error) {
            console.log('error deleting video file action', error)
        }
    },
};

export const getters = {
    
};
