import http from "../common/http";

class AmazonService {
    findCategories() {
        return http.request('get', `/amazon/product/categories`);
    }

    createProduct(data) {
        return http.request('post', `/amazon/product/create`, data);
    }   

    updateProduct(data) {
        return http.request('put', `/amazon/product/update`, data);
    }

    updateProductOffers(data) {
        return http.request('post', `/amazon/product/offers/update`, data);
    } 
  
    findAllProducts() {
        return http.request('get', `/amazon/product/find-all`);
    }

    findOneProduct(id) {
        return http.request('get', `/amazon/product/find-one?id=${id}`);
    }

     deleteProduct(id) {
        return http.request('delete', `/amazon/product/delete?id=${id}`);
    }

    /***************************** START - CART FUNCTIONS ****************************************/

    addCartProduct(data) {
        return http.request('post', '/amazon/cart/add', data);
    }
      
    updateCartProduct(data) {
        return http.request('put', '/amazon/cart/update', data);
    }

    findAllCartProducts(userId) {
        return http.request('get', `/amazon/cart/find-all?userId=${userId}`);
    }

    findOneCartProduct(cartId) {
        return http.request('get', `/amazon/cart/find-one?cartId=${cartId}`);
    }

    deleteCartProduct(cartId) {
        return http.request('delete', `/amazon/cart/delete?cartId=${cartId}`);
    }

    findCartProductsBySessionToken(sessionToken) {
        return http.request('get', `/amazon/cart/find-one/session-token?sessionToken=${sessionToken}`);
    }

    /***************************** END - CART FUNCTIONS ****************************************/

    /***************************** START - ORDER FUNCTIONS ****************************************/

    findOrderBySessionToken(sessionToken) {
        return http.request('get', `/amazon/order/find-one/session-token?sessionToken=${sessionToken}`);
    }

    findAllOrders(userId) {
        return http.request('get', `/amazon/order/find-all?userId=${userId}`);
    }

    findOneOrder(userId, orderNumber) {
        return http.request('get', `/amazon/order/find-one?userId=${userId}&orderNumber=${orderNumber}`);
    }
    
}

export default new AmazonService();
