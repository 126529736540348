export default {
    data() {
      return {
        errors: {},
      };
    },
    methods: {
      validateEmail(email) {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(email);
      },
      validateRequired(value) {
        return value.trim() !== '';
      },
      validateMinLength(value, minLength) {
        return value.trim().length >= minLength;
      },
      validateMaxLength(value, maxLength) {
        return value.trim().length <= maxLength;
      },
      validateForm(fields) {
        const errors = {};
  
        for (const field of fields) {
          const { name, value, rules } = field;
          for (const rule in rules) {
            if (rule === 'required' && !this.validateRequired(value)) {
              errors[name] = rules[rule].message;
            } else if (rule === 'email' && !this.validateEmail(value)) {
              errors[name] = rules[rule].message;
            } else if (rule === 'min' && !this.validateMinLength(value, rules[rule].length)) {
              errors[name] = rules[rule].message;
            } else if (rule === 'max' && !this.validateMaxLength(value, rules[rule].length)) {
              errors[name] = rules[rule].message;
            }
          }
        }
  
        this.errors = errors;
        return Object.keys(errors).length === 0;
      },
    },
  };
  