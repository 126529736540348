import { getFirebaseBackend } from '../../authUtils.js'
import AuthService from '../../services/auth.service'

export const state = {
    currentUser: JSON.parse(localStorage.getItem('user')),
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('user', newValue)
        //saveState('auth.currentUser', newValue)
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    // eslint-disable-next-line no-unused-vars
    init({ state, dispatch }) {
        dispatch('validate')
    },

    // Logs in the current user.
    // logIn({ commit, dispatch, getters }, { email, password } = {}) {
    //     if (getters.loggedIn) return dispatch('validate')

    //     return getFirebaseBackend().loginUser(email, password).then((response) => {
    //         const user = response
    //         commit('SET_CURRENT_USER', user)
    //         return user
    //     });
    // },

    // Logs in the current user.
    async logIn({ commit, dispatch, getters }, payload) {
        //if (getters.loggedIn) return dispatch('validate')
        
        try {
            let response = await AuthService.login(payload)
            if(!response.user) {
                return response
            }
            const user = response.message == "SUCCESS" ? response.user : null
            commit('SET_CURRENT_USER', user)
            return response
        }
        catch(error) {
            console.log('logIn action', error)
            throw error
        }
    },

    async updateUserDetails({ commit }, payload) {
        
        try {
            let response = await AuthService.updateUserDetails(payload)
            if(response) {
                const user = response
                commit('SET_CURRENT_USER', user) 
            }
            return response
        }
        catch(error) {
            console.log('logIn action', error)
            throw error
        }
    },

    // register the user
    async register({ commit, dispatch, getters }, payload) {
        //if (getters.loggedIn) return dispatch('validate')

        try {
            let response = await AuthService.register(payload)
            const user = response
            //commit('SET_CURRENT_USER', null)
            return user
        }
        catch(error) {
            console.log('register action', error)
            throw error
        }
    },

    // verify the user
    async verifyEmail({ commit, dispatch, getters }, payload) {
        //if (getters.loggedIn) return dispatch('validate')

        try {
            let response = await AuthService.verifyEmail(payload)
            if(!response.user) {
                return response
            }
            const user = response.user
            commit('SET_CURRENT_USER', user)
            return response
        }
        catch(error) {
            console.log('verify action', error)
            throw error
        }
    },

    // Resend code 
    async resendVerificationCode({ commit, dispatch, getters }, payload) {
        //if (getters.loggedIn) return dispatch('validate')

        try {
            let response = await AuthService.resendVerificationCode(payload)
            return response
        }
        catch(error) {
            console.log('resend action', error)
            throw error
        }
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        commit('SET_CURRENT_USER', null)
        return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-unused-vars
            getFirebaseBackend().logout().then((response) => {
                resolve(true);
            }).catch((error) => {
                reject(this._handleError(error));
            })
        });
    },

    setUser({ commit }, user) {
        commit('SET_CURRENT_USER', user);
    },

    // register the user
    // register({ commit, dispatch, getters }, { username, email, password } = {}) {
    //     if (getters.loggedIn) return dispatch('validate')

    //     return getFirebaseBackend().registerUser(username, email, password).then((response) => {
    //         const user = response
    //         commit('SET_CURRENT_USER', user)
    //         return user
    //     });
    // },

    // register the user
    // eslint-disable-next-line no-unused-vars
    resetPassword({ commit, dispatch, getters }, { email } = {}) {
        if (getters.loggedIn) return dispatch('validate')

        return getFirebaseBackend().forgetPassword(email).then((response) => {
            const message = response.data
            return message
        });
    },

    // Validates the current user's token and refreshes it
    // with new data from the API.
    // eslint-disable-next-line no-unused-vars
    validate({ commit, state }) {
        if (!state.currentUser) return Promise.resolve(null)
        const user = getFirebaseBackend().getAuthenticatedUser();
        commit('SET_CURRENT_USER', user)
        return user;
    },

    isTokenExpired() {
        let token = localStorage.getItem("jwt")
        if (token) {
          const jwtPayload = parseJwt(token)
          if (jwtPayload.exp < Date.now()/1000) {
            // token expired
            localStorage.clear()
            return true
          }
        } 
        else {
            return false
        }
    },
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  
    return JSON.parse(jsonPayload);
  }

// function parseJwt (token) {
//     var base64Url = token.split('.')[1]
//     var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
//     var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
//         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
//     }).join(''))

//     return JSON.parse(jsonPayload)
// }