import http from "../common/http";

class CampaignService {

  createCampaign(data) {
    return http.request('post', '/campaign/create', data);
  }
  
  updateCampaign(data) {
    return http.request('put', '/campaign/update', data);
  }

  findAllCampaigns(incomeStreamId) {
    return http.request('get', `/campaign/find-all?incomeStreamId=${incomeStreamId}`);
  }

  deleteCampaign(campaignId) {
    return http.request('delete', `/campaign/delete?campaignId=${campaignId}`);
  }

  findAllUserContributions(incomeStreamId, userId) {
    return http.request('get', `/campaign/find-all/user/contributions?incomeStreamId=${incomeStreamId}&userId=${userId}`);
  }

  createCampaignComment(data) {
    return http.request('post', '/campaign/comment/create', data);
  }

  updateCampaignComment(data) {
    return http.request('put', `/campaign/comment/update`, data);
  }

  findAllCampaignComments(campaignId) {
    return http.request('get', `/campaign/comment/find-all?campaignId=${campaignId}`);
  }


  deleteCampaignComment(commentId, isReply) {
    return http.request('delete', `/campaign/comment/delete?commentId=${commentId}&isReply=${isReply}`);
  }
}

export default new CampaignService();
