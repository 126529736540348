import http from "../common/http";

class AddressService {

  create(data) {
    return http.request('post', '/address/create', data);
  }
  
  update(data) {
    return http.request('put', '/address/update', data);
  }

  findAll(userId) {
    return http.request('get', `/address/find-all?userId=${userId}`);
  }

  findOne(userId, addressId) {
    return http.request('get', `/address/find-one?userId=${userId}&addressId=${addressId}`);
  }

  deleteOne(data) {
    return http.request('post', `/address/delete`, data);
  }
}

export default new AddressService();
