import CampaignService from '../../services/campaign.service'

export const state = {
    campaigns: [],
    userContributions: [],
    campaignComments: [],
};

export const mutations = {

    CREATE_CAMPAIGN(state, value) {
        state.campaigns.push(value)
    },

    UPDATE_CAMPAIGN(state, value) {
        if(value) {
            let index = state.campaigns.findIndex(campaign => campaign.id == value.id)
            state.campaigns[index] = value
        }
    },

    FIND_ALL_CAMPAIGNS(state, value) {
        state.campaigns = value
    },

    DELETE_CAMPAIGN(state, value) {
        let index = state.campaigns.findIndex((res) => res.id == value)
        state.campaigns.splice(index, 1)
    }, 
    
    FIND_ALL_USER_CONTRIBUTIONS(state, value) {
        state.userContributions = value
    },

    ADD_CAMPAIGN_COMMENT(state, payload) {
        console.log('ADD_CAMPAIGN_COMMENT', payload)
        if(payload.commentId) {
            let index = state.campaignComments.findIndex(comment => comment.id == payload.commentId)
            state.campaignComments[index].replies = payload.result.replies || []
        }
        else {
            if(!payload.result.replies) { 
                payload.result.replies = []
            }
           state.campaignComments.unshift(payload.result) 
        }
    },

    UPDATE_CAMPAIGN_COMMENT(state, payload) {
        console.log('UPDATE_CAMPAIGN_COMMENT', payload)
        let index = state.campaignComments.findIndex(comment => comment.id == payload.commentId)
        state.campaignComments[index] = payload.result 
        console.log('state.campaignComments[index]', state.campaignComments[index])
        //if(payload.commentType == 'edit-comment') {
            // console.log('state.campaignComments', state.campaignComments)
            // let index = state.campaignComments.findIndex(comment => comment.id == payload.commentId)
            // console.log('index', index)
            // payload.replies = payload.result.replies || []
            
        //}
        // else {
        //     let index = state.campaignComments.findIndex(comment => comment.id == payload.commentId)
        //     if(!payload.replies) { 
        //         payload.replies = []
        //     }
        //     //if(state.campaignComments[index].replies) {
        //         let replyIndex = state.campaignComments[index].replies.findIndex(reply => reply.id == payload.replyId)
        //         state.campaignComments[index].replies[replyIndex] = payload.result  
        //     //}
            
        // }
    },

    FIND_CAMPAIGN_COMMENTS(state, value) {
        state.campaignComments = value
    },

    DELETE_CAMPAIGN_COMMENT(state, value) {
        let index = state.comments.findIndex((res) => res.id == value)
        state.campaignComments.splice(index, 1)
    },
};

export const actions = {

    async createCampaign({commit}, payload) {
        try {
            let response = await CampaignService.createCampaign(payload)
            commit('CREATE_CAMPAIGN', response[0])
            return response
        }
        catch(error) {
            console.log('create campaign action', error)
        }
    },
    
    async updateCampaign({commit}, payload) {
        try {
            let response = await CampaignService.updateCampaign(payload)
            commit('UPDATE_CAMPAIGN', response)
            return response
        }
        catch(error) {
            console.log('update campaign action', error)
        }
    },

    async findAllCampaigns({ commit }, incomeStreamId) {
        try {
            let response = await CampaignService.findAllCampaigns(incomeStreamId)
            commit('FIND_ALL_CAMPAIGNS', response)
            console.log('find all response', response)
            return response
        }
        catch(error) {
            console.log('find all campaign action', error)
            throw error
        }
    },

    async deleteCampaign({commit}, campaignId) {
        try {
            let response = await CampaignService.deleteCampaign(campaignId)
            commit('DELETE_CAMPAIGN', campaignId)
            return response
        }
        catch(error) {
            console.log('delete campaign action', error)
        }
    },

    async findAllUserContributions({ commit }, payload) {
        try {
            const { incomeStreamId, userId } = payload
            let response = await CampaignService.findAllUserContributions(incomeStreamId, userId)
            commit('FIND_ALL_USER_CONTRIBUTIONS', response)
            console.log('find all contributions for user', response)
            return response
        }
        catch(error) {
            console.log('find all user contrib action', error)
            throw error
        }
    },

    async createCampaignComment({commit}, payload) {
        try {
            let response = await CampaignService.createCampaignComment(payload)
            payload.result = response[0]
            commit('ADD_CAMPAIGN_COMMENT', payload)
            return response
        }
        catch(error) {
            console.log('create campaign comment action', error)
        }
    },

    async updateCampaignComment({commit}, payload) {
        try {
            let response = await CampaignService.updateCampaignComment(payload)
            payload.result = response[0]
            commit('UPDATE_CAMPAIGN_COMMENT', payload)
            return response
        }
        catch(error) {
            console.log('update campaign comment action', error)
        }
    },

    async findAllCampaignComments({ commit }, campaignId) {
        try {
            let response = await CampaignService.findAllCampaignComments(campaignId)
            commit('FIND_CAMPAIGN_COMMENTS', response)
            return response
        }
        catch(error) {
            console.log('find all campaign comments action', error)
        }
    },

    async deleteCampaignComment({commit}, payload) {
        try {
            const { campaignId, commentId } = payload
            let response = await CampaignService.deleteCampaignComment(campaignId, commentId)
            commit('DELETE_CAMPAIGN_COMMENT', commentId)
            return response
        }
        catch(error) {
            console.log('delete campaign comment action', error)
        }
    },
};
