import http from "../common/http";

class SalesService {

  findAll(userId) {
    return http.request('get', `/sales/find-all?userId=${userId}`);
  }
}

export default new SalesService();
