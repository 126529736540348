import http from "../common/http";

class StoreService {
  findAllPortfolios(userId) {
    return http.request('get', `/store/portfolio/find-all?userId=${userId}`);
  }

  findOnePortfolio(portfolioId, userId) {
    return http.request('get', `/store/portfolio/find-one?portfolioId=${portfolioId}&userId=${userId}`);
  }

  findOnePortfolioProduct(portfolioId, productId) {
    return http.request('get', `/store/portfolio/product/find-one?portfolioId=${portfolioId}&productId=${productId}`);
  }
  
  deleteProductFromPortfolio(portfolioId, productId) {
    return http.request('delete', `/store/portfolio/product/delete?portfolioId=${portfolioId}&productId=${productId}`);
  }
}

export default new StoreService();