import http from "../common/http";

class VideoService {
  create(data) {
    return http.request('post', '/video/create', data);
  }
  
  update(data) {
    return http.request('put', '/video/update', data);
  }

  findAll(streamId) {
    return http.request('get', `/video/find-all?streamId=${streamId}`);
  }

  findOne(id) {
    return http.request('get', `/video/find-one?id=${id}`);
  }

  delete(id) {
    return http.request('delete', `/video/delete?id=${id}`);
  }
}

export default new VideoService();
