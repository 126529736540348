import CartService from '../../services/cart.service'

export const state = {
    cartProducts: [],
    selectedCartProduct: {},
    orders: [],
};

export const mutations = {
    ADD_TO_CART(state, value) {
        state.cartProducts.push(value)
    },

    UPDATE_CART(state, value) {
        let index = state.cartProducts.findIndex(product => product.id == value.id)
        state.cartProducts[index] = value
    },

    FIND_CART_PRODUCTS(state, value) {
        state.cartProducts = value
        state.cartProducts.forEach((product, index) => {
            state.cartProducts[index].options = JSON.parse(product.options)
        })
    },

    FIND_ONE(state, value) {
        state.selectedCartProduct = value
    },

    DELETE_FROM_CART(state, value) {
        let index = state.cartProducts.findIndex((res) => res.id == value)
        state.cartProducts.splice(index, 1)
    },
};

export const actions = {
    
    async addToCart({commit}, payload) {
        try {
            let response = await CartService.create(payload)
            commit('ADD_TO_CART', response[0])
            return response
        }
        catch(error) {
            console.log('add to cart action', error)
        }
    },
    
    async updateCart({commit}, payload) {
        try {
            let response = await CartService.update(payload)
            commit('UPDATE_CART', response[0])
            return response
        }
        catch(error) {
            console.log('update cart action', error)
        }
    },

    async findCartProducts({ commit }, userId) {
        try {
            let response = await CartService.findAll(userId)
            commit('FIND_CART_PRODUCTS', response)
            return response
        }
        catch(error) {
            console.log('find cart products action', error)
            throw error
        }
    },

    async findCartProduct({ commit }, productId) {
        try {
            let response = await CartService.findOne(productId)
            commit('FIND_ONE', response[0])
            return response
        }
        catch(error) {
            console.log('find one cart product action', error)
        }
    },

    async deleteFromCart({commit}, productId) {
        try {
            let response = await CartService.delete(productId)
            commit('DELETE_FROM_CART', productId)
            return response
        }
        catch(error) {
            console.log('delete from cart action', error)
        }
    },

    async updateShippingMethod({ commit }, payload) {
        try {
            let response = await CartService.updateShippingMethod(payload)
            console.log('FIND_CART_PRODUCTS', response)
            commit('FIND_CART_PRODUCTS', response)
            return response
        }
        catch(error) {
            console.log('find cart products action', error)
            throw error
        }
    },
};

export const getters = {
    
};
