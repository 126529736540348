import PortfolioService from '../../services/portfolio.service'

export const state = {
    portfolioStreams: [],
    selectedPortfolioStream: {}
};

export const mutations = {
    ADD_TO_PORTFOLIO(state, value) {
        state.portfolioStreams.push(value)
    },

    // UPDATE_PLATFORM(state, value) {
    //     let index = state.platforms.findIndex(platform => platform.id == value.id)
    //     state.platforms[index] = value
    // },

    SET_PORTFOLIO_STREAMS(state, value) {
        state.portfolioStreams = value
    },

    SET_PORTFOLIO_STREAM(state, value) {
        state.selectedPortfolioStream = value
    },

    DELETE_FROM_PORTFOLIO(state, value) {
        let index = state.portfolioStreams.findIndex((res) => res.id == value)
        state.portfolioStreams.splice(index, 1)
    },
};

export const actions = {
    
    async addToPortfolio({commit}, payload) {
        try {
            let response = await PortfolioService.add(payload)
            console.log('respnse for add to port', response)
            commit('ADD_TO_PORTFOLIO', response[0])
            return response
        }
        catch(error) {
            console.log('create portfolio action', error)
        }
    },

    async findPortfolioStreams({ commit }, userId) {
        try {
            let response = await PortfolioService.findAll(userId)
            commit('SET_PORTFOLIO_STREAMS', response)
            return response
        }
        catch(error) {
            console.log('find all portfolio streams action', error)
            throw error
        }
    },

    async findPortfolioStream({ commit }, payload) {
        try {
            const { streamId, userId } = payload;
            let response = await PortfolioService.findOne(streamId, userId)
            console.log('payload', payload)
            commit('SET_PORTFOLIO_STREAM', response[0])
            return response
        }
        catch(error) {
            console.log('find one platform action', error)
        }
    },

    async deleteFromPortfolio({commit}, payload) {
        const { streamId, userId } = payload;
        try {
            console.log('delete payload', payload)
            let response = await PortfolioService.delete(streamId, userId)
            commit('DELETE_FROM_PORTFOLIO', streamId)
            return response
        }
        catch(error) {
            console.log('delete portfolio stream action', error)
        }
    },
};

export const getters = {
    
};
