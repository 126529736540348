import http from "../common/http";

class CartService {
  create(data) {
    return http.request('post', '/cart/add', data);
  }
  
  update(data) {
    return http.request('put', '/cart/update', data);
  }

  findAll(userId) {
    return http.request('get', `/cart/find-all?userId=${userId}`);
  }

  findOne(cartId) {
    return http.request('get', `/cart/find-one?cartId=${cartId}`);
  }

  delete(cartId) {
    return http.request('delete', `/cart/delete?cartId=${cartId}`);
  }

  findCartBySessionToken(sessionToken) {
    return http.request('get', `/cart/find-one/session?sessionToken=${sessionToken}`);
  }
}

export default new CartService();
